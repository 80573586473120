.msl-flx {
    display: inline-flex;
    align-items: center;
  }
  .msl-vars {
    --menu-max-height: 400px;
    --input-width: 300px;
    --font-size: 16px;
    --border-radius: 8px 8px 8px 8px;
    --active-menu-shadow: 0 2px 8px 0px #b1b1b1;
    --line-height: 1.4;
    --active-menu-background: white;
    --active-menu-radius: var(--border-radius);
  }
  
  .msl-wrp * {
    line-height: var(--line-height);
  }
  
  .msl-wrp {
    width: var(--input-width);
    outline: none;
    position: relative;
    border-radius: var(--border-radius);
  }
  .msl-disabled {
    pointer-events: none !important;
  }
  .msl-disabled > .msl {
    background-color: rgb(246, 246, 246);
  }
  .msl {
    overflow: hidden;
    border: 1px solid;
    border-color: rgb(156, 156, 156);
    border-radius: var(--border-radius);
    outline: none;
    cursor: text;
    transition: border-radius 200ms;
  }
  .msl:not(.msl-active, .msl-active-up):hover {
    border-color: rgb(80, 80, 80);
  }
  .msl-active {
    background: var(--active-menu-background);
    box-shadow: var(--active-menu-shadow);
    z-index: 9999;
    border-color: transparent;
    border-radius: var(--active-menu-radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    transition: border-top-left-radius 400ms, border-top-right-radius 400ms;
  }
  .msl-active-up {
    background: var(--active-menu-background);
    box-shadow: var(--active-menu-shadow);
    z-index: 9999;
    border-color: transparent;
    border-radius: var(--active-menu-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: border-bottom-left-radius 400ms, border-bottom-right-radius 400ms;
  }
  .msl-active ~ .msl-options {
    background: var(--active-menu-background);
    z-index: 9999;
    box-shadow: var(--active-menu-shadow);
    clip-path: inset(0px -10px -10px -10px);
    max-height: var(--menu-max-height);
    border-radius: var(--active-menu-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: border-bottom-left-radius 0.5s ease-in, border-bottom-right-radius 0.5s ease-in;
  }
  .msl-active-up ~ .msl-options {
    background: var(--active-menu-background);
    z-index: 9999;
    bottom: 99%;
    box-shadow: var(--active-menu-shadow);
    clip-path: inset(-10px -10px 0 -10px);
    max-height: var(--menu-max-height);
    border-radius: var(--active-menu-radius);
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    transition: border-top-left-radius 700ms, border-top-right-radius 700ms;
  }
  .msl-options {
    position: absolute;
    width: 100%;
    background: white;
    max-height: 0;
    overflow: auto;
    scrollbar-color: rgb(0, 0, 0, 0.1) transparent;
    scrollbar-width: thin;
    border-radius: var(--border-radius);
  }
  .msl-input-wrp {
    display: inline-block;
    vertical-align: top;
  }
  .msl-input {
    display: inline-block;
    vertical-align: top;
    outline: none;
    margin: 5px;
    padding: 3px;
    font-size: var(--font-size);
  }
  
  .msl-input::before {
    line-height: var(--line-height);
    color: rgb(146, 146, 146);
  }
  .msl-input[data-placeholder]:not([data-placeholder=""]):empty::before {
    content: attr(data-placeholder);
  }
  .msl-chip {
    background: rgb(238, 238, 238);
    border-radius: 5px;
    margin: 5px;
    padding: 3px 3px 3px 6px;
    white-space: nowrap;
    font-size: var(--font-size);
    display: inline-flex;
    align-items: center;
    line-break: anywhere;
    white-space: break-spaces;
  }
  .msl-single-value {
    display: inline-block;
    margin: 5px;
    padding: 3px;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size);
    line-break: strict;
    white-space: nowrap;
  }
  .msl-options::-webkit-scrollbar {
    width: 8px;
  }
  .msl-options::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .msl-options::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  
  .msl-options::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 0, 0, 0.2);
  }
  .msl-options .msl-option,
  .msl-grp-title {
    font-size: var(--font-size);
    border-radius: 5px;
    padding: 7px 5px;
    cursor: pointer;
    margin: 4px 6px 4px;
    line-break: anywhere;
    line-height: var(--line-height);
    outline: none;
    white-space: break-spaces;
    transition: background 200ms;
  }
  
  .msl-wrp:focus-visible,
  .msl-option:focus-visible,
  .msl-btn:focus-visible {
    outline: 2px solid rgb(0, 119, 255);
  }
  
  .msl-grp-title {
    font-size: calc(var(--font-size) - 2px);
    cursor: default;
    background: #fafafa;
    opacity: 0.7;
  }
  
  .msl-options .msl-option:last-child {
    margin-bottom: 5px;
  }
  .msl-options .msl-option:hover,
  .msl-option:focus {
    background: rgb(233, 233, 233);
  }
  .msl-option-active {
    background: #e6f4fa;
    color: #0351ff;
  }
  .msl-option-disable {
    color: hsla(0, 0%, 0%, 0.3);
  }
  
  .msl-btn {
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 0;
    justify-content: center;
    cursor: pointer;
    background: transparent;
  }
  .msl-actions {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translatey(-50%);
  }
  .msl-arrow-btn,
  .msl-clear-btn {
    width: 25px;
    height: 25px;
  }
  
  .msl-clear-btn:hover,
  .msl-arrow-btn:hover {
    background: lightgray;
  }
  
  .msl-close-icn > line,
  .msl-arrow-icn > line {
    stroke-width: 1;
  }
  
  .msl-arrow-icn {
    height: 20px;
    width: 20px;
  }
  
  .msl-chip-delete {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-left: 3px;
    background: rgb(218, 218, 218);
    color: rgb(0, 0, 0, 0.6);
  }
  .msl-chip-delete:hover {
    background: lightgray;
  }
  
  .msl-chip-delete .msl-close-icn > line {
    stroke-width: 1;
  }
  
  .msl-close-icn {
    height: 10px;
    width: 10px;
  }