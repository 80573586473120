.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker-ignore-onclickoutside,
.react-datepicker__input-container input {
  border-width: 0 !important;
  width: 85px;
  border: none;
  font-family: "NunitoSans-Regular";
}
.react-datepicker-wrapper input{
    border: none;
}

.react-datepicker-popper {
  z-index: 1000000 !important;
}